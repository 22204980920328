/*VARIABLES*/
$large: "(max-width:1300px)";
$medium: "(max-width:991px)";
$small: "(max-width:767px)";
$extra-small: "(max-width:576px)";

/*IMPORT LIBS*/
@import "lib/sweetalert2.scss";

/*IMPORT TEMPLATES*/
@import "global.scss";

/*IMPORT VIEWS*/
@import "views/table.scss";