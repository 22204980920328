main{
    max-width:1300px;
    width:100%;
    margin:auto;
    padding:0 0 0 40px;
    box-sizing:border-box;
    .table-section{
        margin-top:60px;
        .table-title{
            display:flex;
            span{
                position:relative;
                width:calc(100% - 36px);
                padding:20px 0;
                font-size:24px;
                color:#ffffff;
                text-align:center;
                background-color:#76b729;
            }
            &:hover{
                .exclude-section{
                    visibility:visible;
                }
            }
            .exclude-section{
                visibility:hidden;
                display:block;
                top:0;
                right:0px;
                width:37px;
                height:68px;
                background-color:white;
                background-image:url(../images/exclude-icon.png);
                background-repeat:no-repeat;
                background-position:center;
                &:hover{
                    display:block;
                }
            }
        }
        table{
            table-layout:fixed;
            margin-top:11px;
            width:100%;
            border-collapse:collapse;
            thead{
                th{
                    padding:30px 20px;
                    font-size:12px;
                    font-weight:bold;
                    color:#2e5104;
                    background-color:#eff0ee;
                    border-left:7px solid white;
                    border-right:7px solid white;
                    border-bottom:1px solid white;
                    &:first-of-type{
                        font-size:18px;
                        width:25%;
                        background-color:white;
                        border-left:none;
                        border-bottom:1px solid #eff0ee;
                    }
                    &:last-of-type{
                        width:30px;
                        padding-left:0;
                        padding-right:0;
                        background-color:white;
                        border-right:none;
                    }
                    &.service-head{
                        &.active{
                            background-color:#d3eeb9;
                        }
                    }
                }
            }
            tbody{
                tr{
                    &:hover{
                        .exclude{
                            display:block;
                        }
                    }
                    &.excluded{
                        td:not(.exclude-box){
                            background-color:#dee3d2!important;
                            color:gray;
                            img{
                                filter:grayscale(100%);
                            }
                        }
                    }
                    td{
                        position:relative;
                        padding:15px 5px;
                        font-size:14px;
                        font-weight:bold;
                        color:#000000;
                        text-align:center;
                        background-color:#eff0ee;
                        border-left:7px solid white;
                        border-right:7px solid white;
                        border-bottom:1px solid white;
                        &:first-of-type{
                            font-weight:normal;
                            text-align:left;
                            width:25%;
                            background-color:transparent;
                            border-left:none;
                            border-bottom:1px solid #eff0ee;
                        }
                        &:last-of-type{
                            width:30px;
                            padding-left:0;
                            padding-right:0;
                            background-color:white;
                            border-right:none;
                        }
                        &:hover{
                            &.ask-mark{
                                .tooltip{
                                    display:block;
                                }
                            }
                        }
                        &.service-value{
                            &.active{
                                background-color:#d3eeb9;
                            }
                        }
                        &.ask-mark{
                            &:after{
                                content:'';
                                position:absolute;
                                right:0;
                                width:19px;
                                height:19px;
                                background-image:url(../images/ask-icon.png);
                            }
                            .tooltip{
                                display:none;
                                position:absolute;
                                z-index:1000;
                                left:0;
                                top:90%;
                                padding:20px 15px;
                                width:100%;
                                font-size:12px;
                                background-color:white;
                                border:1px solid #76b729;
                                box-sizing:border-box;
                                word-wrap:break-word;
                            }
                        }
                        .exclude{
                            display:none;
                            position:absolute;
                            top:0;
                            right:3px;
                            width:30px;
                            height:100%;
                            background-image:url(../images/exclude-icon.png);
                            background-repeat:no-repeat;
                            background-position:center;
                            &:hover{
                                display:block;
                            }
                        }
                    }
                }
            }
        }
    }
    .excluded-list{
        display:none;
        margin:80px 0;
        padding-top:80px;
        border-top:1px solid #eff0ee;
    }
    .table-plans-prices{
        margin:80px 0;
        padding-top:80px;
        border-top:1px solid #eff0ee;
        .contracts-list{
            display:flex;
            justify-content:flex-end;
            .contract{
                max-width:192px;
                width:100%;
                padding:10px;
                margin:0 20px;
                font-size:14px;
                font-weight:bold;
                color:#2e5104;
                text-align:center;
                border:1px solid #eff0ee;
                box-shadow:4px 4px 5px 0px #d2ddc6;
                cursor:pointer;
                &:hover{
                    color:white;
                    background-color:#76b729;
                }
                &.active{
                    color:white;
                    background-color:#76b729;
                }
            }
        }
        .prices-plans-list{
            table-layout:fixed;
            margin-top:50px;
            width:100%;
            border-collapse:collapse;
            td{
                padding:30px 5px;
                text-align:center;
                background-color:#eff0ee;
                border-left:7px solid white;
                border-right:7px solid white;
                &:first-of-type{
                    width:25%;
                    background-color:transparent;
                    border-left:none;
                }
                &:last-of-type{
                    width:30px;
                    padding-left:0;
                    padding-right:0;
                    background-color:white;
                    border-right:none;
                }
                &.plan{
                    &.active{
                        background-color:#d3eeb9;
                    }
                    .price{
                        font-size:20px;
                        font-weight:bold;
                        color:#76b729;
                    }
                    .text{
                        margin-top:8px;
                        font-size:14px;
                        color:black;
                    }
                    .choose{
                        display:block;
                        width:109px;
                        padding:13px 10px;
                        margin:18px auto 0;
                        font-size:11px;
                        color:white;
                        background-color:#76b729;
                        border-radius:5px;
                        box-sizing:border-box;
                    }
                    .download-pdf{
                        display:block;
                        width:109px;
                        padding:13px 10px;
                        margin:15px auto 0;
                        font-size:11px;
                        color:black;
                        border:1px solid #76b729;
                        border-radius:5px;
                        box-sizing:border-box;
                    }
                }
            }
        }
        .additional-info{
            display:none;
            width:500px;
            margin-top:80px;
            text-align:right;
            .title{
                font-size:30px;
                padding-top:0;
            }
            .input-group{
                margin:10px 0;
                label{
                    font-size:14px;
                    font-weight:bold;
                    margin-right:20px;
                }
                input{
                    width:200px;
                    padding:5px 10px;
                }
            }
            .send-form{
                display:inline-block;
                padding:13px 10px;
                margin-top:18px;
                font-size:11px;
                color:white;
                background-color:#76b729;
                border-radius:5px;
            }
        }
    }
    .generate-pdf{
        display:none;
    }
}

/*RWD LARGE*/
@media #{$large}{
	
}
/*RWD MEDIUM*/
@media #{$medium}{

}
/*RWD SMALL*/
@media #{$small}{

}
/*RWD EXTRA-SMALL*/
@media #{$extra-small}{

}